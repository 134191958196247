import type { Axios, AxiosResponse } from "axios";

import type { Command } from "../type";
export interface GetStateCommandInput {
  key: string;
}
export class GetStateCommand implements Command<string> {
  constructor(private readonly commandOptions: GetStateCommandInput) {}
  async execute(httpClient: Axios): Promise<string> {
    const response: AxiosResponse = await httpClient.get(
      `/state/${this.commandOptions.key}`,
      {
        responseType: "text",
        transformResponse: (data) => data,
      },
    );
    return response.data;
  }
}
